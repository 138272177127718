
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avs-portal-frontend-primary: mat.m2-define-palette(mat.$m2-red-palette, A700);
$avs-portal-frontend-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$avs-portal-frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$avs-portal-frontend-theme: mat.m2-define-light-theme((
  color: (
    primary: $avs-portal-frontend-primary,
    accent: $avs-portal-frontend-accent,
    warn: $avs-portal-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($avs-portal-frontend-theme);

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap.scss";

@import 'mapbox-gl/dist/mapbox-gl.css';

.actionbar {
  display: flex;
 justify-content: flex-end;
  width: 100%
}

/* @import 'highlight.js/styles/github.css'; */ /* Wenn dieser import hinterlegt ist, wird der text schwarz gefärbt. wenn nicht vorhanden ist er rosa? */
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(0, 0, 0) !important;
  border-radius: 0;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgb(255, 0, 0) !important;
  border-radius: 0;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-radius: 0;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  color: rgb(0, 0, 0);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgb(0, 0, 0);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000000;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgb(212, 253, 5);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {background-color: #ffffff !important; }
.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 0px !important;
}
/* https://stackoverflow.com/questions/54029193/change-color-of-matinput */


.transparent .mat-dialog-container {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.0);
}


// map

:root {
  --building-color: #FF9800;
  --house-color: #0288D1;
  --shop-color: #7B1FA2;
  --warehouse-color: #558B2F;
  --shop-color-2: #767676;
  --active-project-color: #00901d;
  --deactivated: #767676;
  --active-group-color: #2d4180;
  --active-route-station-color: #7B1FA2;

}

/*
 * Optional: Makes the sample page fill the window.
 */
/* html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}


#map {
  height: 100%;
  width: 100%;
} */

/*
 * Property styles in unhighlighted state.
 */
.property {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
}

/* new Hover properties */
.property:hover {
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 8px 15px;
  width: auto;
}

.property:hover::after {
  border-top: 9px solid #FFFFFF;
}

.property:hover .details {
  display: flex;
  margin-left: 6px;
}
.property:hover .group-container {
  display: flex;
  flex-direction: column;
}
.property:hover .icon svg {
  width: 50px;
  height: 50px;
}

/* new hover properties ende */
.property::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: none;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}


.property .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
}



.property .icon svg {
  height: 20px;
  width: auto;
}

.property .details {
  display: none;
  flex-direction: column;
  flex: 1;
}
.property .group-container {
  display: none;
  flex-direction: row;
  flex: 1;
}
.property .address {
  color: #9E9E9E;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.property .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.property .features > div {
  align-items: center;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/*
 * Property styles in highlighted state.
 */
.property.highlight {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: 80px;
  padding: 8px 15px;
  width: auto;
} /* check */

.property.highlight::after {
  border-top: 9px solid #FFFFFF;
}

.property:hover .details {
  display: flex;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bolder;
}
.property.highlight .group-container {
  display: flex;
}


.property.highlight .icon svg {
  width: 50px;
  height: 50px;
}

/* Project Folder */

.property.highlight:has(.fa-folder-tree):not(.deactivated) .icon {
  color: var(--active-project-color);
}

.property.highlight:has(.fa-folder-tree):not(.deactivated):hover .icon {
  color: var(--active-project-color);
}

.property:not(.highlight):not(.deactivated):has(.fa-folder-tree) {
  background-color: var(--active-project-color);
}

.property:not(.highlight):not(.deactivated):has(.fa-folder-tree):hover {
  background-color: white;
}

.property:not(.highlight):not(.deactivated):has(.fa-folder-tree):hover .icon{
  color: var(--active-project-color);
}
/* deactivated Project Folder */

.property:not(.highlight):not(.deactivated):has(.fa-folder-tree)::after {
  border-top: white;
}

.property.highlight:has(.fa-folder-tree):has(.deactivated) .icon {
  color: var(--deactivated);
}

.property:not(.highlight):has(.fa-folder-tree):has(.deactivated) {
  background-color: var(--deactivated);
}

.property:not(.highlight):has(.fa-folder-tree):has(.deactivated)::after {
  border-top: white
}

.property:not(.highlight):has(.deactivated):has(.fa-folder-tree):hover .icon{
  color: var(--deactivated)
}




/* Group Folder */

.property.highlight:has(.fa-folder):not(.deactivated) .icon {
  color: var(--active-group-color);
}

.property.highlight:has(.fa-folder):not(.deactivated) .icon {
  color: var(--active-group-color);
}
.property.highlight:has(.fa-folder):not(.deactivated):hover .icon {
  color: var(--active-group-color);
}

.property:not(.highlight):not(.deactivated):has(.fa-folder) {
  background-color: var(--active-group-color);
}

/* deactivated group Folder */

.property:not(.highlight):not(.deactivated):has(.fa-folder)::after {
  border-top: white}

.property.highlight:has(.fa-folder):has(.deactivated) .icon {
  color: var(--deactivated);
}

.property:not(.highlight):has(.fa-folder):has(.deactivated) {
  background-color: var(--deactivated);
}

.property:not(.highlight):has(.fa-folder):has(.deactivated)::after {
  border-top: white}

.property:not(.highlight):not(.deactivated):has(.fa-folder):hover {
  background-color: white;
}

.property:not(.highlight):not(.deactivated):has(.fa-folder):hover .icon{
  color: var(--active-group-color);
}

.property:not(.highlight):has(.deactivated):has(.fa-folder):hover .icon{
  color: var(--deactivated)
}

.property:not(.highlight):has(.fa-folder):has(.deactivated) {
  background-color: var(--deactivated);
}




/* routeStationFolder */

.property.highlight:has(.fa-map-pin):not(.deactivated) .icon {
  color: var(--active-route-station-color);
}

.property.highlight:has(.fa-map-pin):not(.deactivated) .icon {
  color: var(--active-route-station-color);
}

.property:not(.highlight):not(.deactivated):has(.fa-map-pin) {
  background-color: var(--active-route-station-color);
}

/* deactivated routeStationFolder */

.property:not(.highlight):not(.deactivated):has(.fa-map-pin)::after {
  border-top: white
}

.property.highlight:has(.fa-map-pin):has(.deactivated) .icon {
  color: var(--deactivated);
}

.group-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
  display: none;
  padding-top: 9px;

}

.group-button:hover {
  background: rgb(235, 235, 235);
}

.route-station-button {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  height: 40px;
  cursor: pointer;
  display: none;
  padding-top: 9px;
}

.route-station-button:hover {
  background: rgb(235, 235, 235);
}

.transit-checkbox{
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  display:flex
}

/* https://developer.mozilla.org/en-US/docs/Web/CSS/:not */

.propertyRS {
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  flex-direction: column;
  padding: 4px;
  position: relative;
  position: relative;
  transition: all 0.3s ease-out;
  width: 30px;
}

/* new Hover properties */
.propertyRS/* :hover */ {
/*   border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: auto;
  padding: 8px 15px;
  width: auto; */
}

.propertyRS/* :hover: */:after {
/*   border-top: 9px solid #FFFFFF; */
}

.propertyRS/* :hover  */.details {
 /*  display: flex;
  margin-left: 6px; */
}
.propertyRS/* :hover */ .group-container {
/*   display: flex;
  flex-direction: column; */
}
.propertyRS/* :hover */ .icon svg {         /* hover */
/*   width: 50px; 
  height: 50px; */
}

/* new hover properties ende */
.propertyRS::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: none;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 1;
}


.propertyRS .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
}



.propertyRS .icon svg {
  height: 20px;
  width: auto;
}

.propertyRS .details {
  display: none;
  flex-direction: column;
  flex: 1;
}
.propertyRS .group-container {
  display: none;
  flex-direction: row;
  flex: 1;
}
.propertyRS .address {
  color: #9E9E9E;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.propertyRS .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.propertyRS .features > div {
  align-items: center;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/*
 * Property styles in highlighted state.
 */
.propertyRS.highlight {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: 80px;
  padding: 8px 15px;
  width: auto;
} /* check */

/* .propertyRS.highlight::after {
  border-top: 9px solid #FFFFFF;
} */

.propertyRS/* :hover */ .details { 
/*   display: flex;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bolder; */
}
.propertyRS.highlight .group-container {
/*   display: flex; */
}


.propertyRS.highlight .icon svg {
/*   width: 50px;
  height: 50px; */
}